import React from 'react';
import {
  FlexibleWidthXYPlot,
  XYPlot,
  Hint,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries, AreaSeries, MarkSeries
} from 'react-vis';
import Table from 'react-bootstrap/Table'; //https://react-bootstrap.github.io/components/button-group/
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Badge from 'react-bootstrap/Badge';
import LineIcon from 'react-lineicons';
import './Home.css';

export class Home extends React.Component {

  constructor(props) {
    super(props);

    var curHr = new Date().getHours();

    let title = "";
    if (curHr < 12) {
      title = 'Good morning';
    } else if (curHr < 18) {
      title = 'Good afternoon';
    } else {
      title = 'Good evening';
    }

    this.btnReloadClick = this.btnReloadClick.bind(this);
    this.btnSymbolClick = this.btnSymbolClick.bind(this);
    this.btnChartGroupClick = this.btnChartGroupClick.bind(this);
    this.btnPeriodClick = this.btnPeriodClick.bind(this);
    this.btnCorrelationRowClick = this.btnCorrelationRowClick.bind(this);

    this.state = {
      textWelcome: title,
      isLoading: false,
      status: null,
      marketday: null,
      symbol: "ETH",
      symbol2: "BTC",
      chartGroup: "15m",
      marketDaysBefore: 3,
    };

  }
  componentDidMount() {
    this.fetchStatus();
    this.fetchMarketDay(1);
  }

  async fetchStatus() {
    this.setState({ status: null });
    let url = 'https://cd001-moxmfqodcq-as.a.run.app/status';
    fetch(url).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          console.log('Loaded');
          this.setState({ status: data });
        });

      } else {
        throw new Error('Something went wrong');
      }
    })
      .then((responseJson) => {
        // Do something with the response
      })
      .catch((error) => {
        console.log(error)
      });
  }

  async fetchMarketDay(days) {
    //this.setState({ marketinfo: null });
    this.setState({ isLoading: true });
    let url = 'https://cd001-moxmfqodcq-as.a.run.app/external/marketinfo?daysBefore=' + days;
    fetch(url).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          console.log('Loaded');
          this.setState({ marketinfo: data, isLoading: false });
        });

      } else {
        throw new Error('Something went wrong');
      }
    })
      .then((responseJson) => {
        // Do something with the response
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  async fetchExport() {
     //this.setState({ marketinfo: null });
     this.setState({ isLoading: true });
     let url = 'https://cd001-moxmfqodcq-as.a.run.app/external/exportdata?daysBefore=30';
     fetch(url).then((response) => {
       if (response.ok) {
         alert('Download export requested...'); 
         this.setState({ isLoading: false });
       } else {
         throw new Error('Something went wrong');
       }
     })
       .then((responseJson) => {
         // Do something with the response
         this.setState({ isLoading: false });
       })
       .catch((error) => {
         console.log(error)
       });
  }

  btnReloadClick() {
    this.fetchStatus();
    this.fetchMarketDay();
  }

  btnExportClick() {
    this.fetchStatus();

  }

  btnSymbolClick(symbol) {
    this.setState({ symbol: symbol });
  }

  btnChartGroupClick(chartGroup) {
    this.setState({ chartGroup: chartGroup });
  }

  async btnPeriodClick(days) {
    await this.fetchMarketDay(days);
    this.setState({ marketDaysBefore: days });
  }

  btnCorrelationRowClick(symbol) {
    if (this.state.symbol2 == symbol)
      symbol = null;
    this.setState({ symbol2: symbol });
  }


  roundNumber(value) {
    let nr = 0;
    if (value == null || typeof(value) === 'undefined')
      return 0;
    nr = 5;
    if (value > 1)
      nr = 4;
    if (value > 10)
      nr = 2;
    if (value > 100)
      nr = 2;
    if (value > 1000)
      nr = 0;
    return value.toFixed(nr);
  }

  colorPercentage(value) {
    let css = "";
    return <span className={css}>{value.toFixed(0)}<small className="text-muted">%</small></span>
  }

  normalize(value, minFrom, maxFrom, minTo = 0, maxTo = 100) {
    return ((maxTo - minTo) / (maxFrom - minFrom)) * (value - maxFrom) + maxTo;
  }

  renderCorrelationTable(symbol) {
    let tablehead = <tr></tr>;
    let tablebodyRows = [];
    let headerCols = [<th key="empty">&nbsp;</th>];
    if (this.state.marketinfo && this.state.marketinfo.marketDays && this.state.marketinfo.marketDays.length > 0) {

      let marketday = this.state.marketinfo.marketDays[0]; //First day
      let selectedColIndex = 1;
      let colIndex = 1;
      for (const property in this.state.marketinfo.correlation) {
        let css = "text-center";
        colIndex++;
        if (property == symbol) {
          css += " hightlightcell";
          selectedColIndex = colIndex;
        }
        let headerKey = "header_" + colIndex;
        headerCols.push(<th key={headerKey} className={css} key={property}>{property}</th>)
      }
      tablehead = (<thead>
        <tr className="border-bottom">
          {headerCols}
        </tr>
      </thead>);

      let strongestCorrelationWithSymbol = null;
      let maxCorrelation = 0;
      tablebodyRows = [];
      for (const property in this.state.marketinfo.correlation) {
        let cols = [];
        colIndex = 1;
        let key1 = "1" + property;
        cols.push(<th key={key1} className="text-end border-end pe-2">{property}</th>);
        let correlations = this.state.marketinfo.correlation[property];
        for (const correlation in correlations) {
          colIndex++;
          let cellCss = "text-center";
          if (colIndex == selectedColIndex) {
            cellCss = " hightlightcell";
          }
          let key2 = "2" + colIndex + "_" + property;
          if (correlations[correlation][0] == 1 || correlations[correlation][0] == 0) {
            cols.push(<td key={key2} className={cellCss}><div className="emptycell">&nbsp;</div></td>);
          } else {
            let key = key2 + correlation + property;

            let cShiftedValues = correlations[correlation];
            let cIcon = <sup className="text-muted badge badge-primary">0</sup>
            let cIndex = 0;

            if (Math.abs(cShiftedValues[0]) < Math.abs(cShiftedValues[1])) {
              cIndex = 1;
              if (Math.abs(cShiftedValues[1]) < Math.abs(cShiftedValues[2])) {
                cIndex = 2;
                if (Math.abs(cShiftedValues[2]) < Math.abs(cShiftedValues[3])) {
                  cIndex = 3;
                  if (Math.abs(cShiftedValues[3]) < Math.abs(cShiftedValues[4])) {
                    cIndex = 4;
                    if (Math.abs(cShiftedValues[4]) < Math.abs(cShiftedValues[5])) {
                      cIndex = 5;
                      if (Math.abs(cShiftedValues[5]) < Math.abs(cShiftedValues[6])) {
                        cIndex = 6;
                        if (Math.abs(cShiftedValues[6]) < Math.abs(cShiftedValues[7])) {
                          cIndex = 7;
                        }
                      }
                    }
                  }
                }
              }
              cIcon = <sup className="text-muted">{cIndex}</sup>
            }
            if (cIndex > 0 && Math.abs(cShiftedValues[cIndex]) > 0.85) {
              cIcon = <sup className="text-warning">{cIndex}</sup> //<Badge bg="success">{cIndex}</Badge>
            }


            let c = (cShiftedValues[cIndex] * 100).toFixed(0);
            if (Math.abs(c) > 75 && Math.abs(c) < 100 && Math.abs(c) > maxCorrelation) {
              maxCorrelation = Math.abs(c);
              strongestCorrelationWithSymbol = correlation;
            }

            let css = "text-center d-block text-nowrap";
            if (c >= 85 || c <= -85)
              css += " text-primary rounded fw-bolder ";
            else if (c >= -15 && c <= 15)
              css += " text-muted rounded fw-lighter";
            else
              css += " font-weight-light";

            cols.push(<td key={key} className={cellCss}><span className={css}>{c}<small className="text-muted">% {cIcon}</small></span></td>);
          }

        }
        cols.push(<th className="text-start border-start ps-2">{property}</th>);
        tablebodyRows.push(<tr className={this.state.symbol2 == property ? "hightlightrow" : ""} onClick={e => this.btnCorrelationRowClick(property)}>{cols}</tr>);
      }
    }

    return (
      <div className="p-4 rounded border-1 border- bg-light">
        <h4><LineIcon name="calculator" /> Cross Correlations</h4>
        <div className="my-2 table-responsive">
          <Table borderless hover size="sm">
            {tablehead}
            <tbody>
              {tablebodyRows}
            </tbody>
            <tfoot>
              <tr className="border-top">
                {headerCols}
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
    );
  }

  renderBasicStatsTable(symbol) {
    let max = 0;
    let min = 0;
    let last = 0;
    let pp = 0;
    let r1 = 0;
    let s1 = 0;
    let v = 0;
    let period;
    if (this.state.marketinfo && this.state.marketinfo.marketDays && this.state.marketinfo.marketDays.length > 0 && this.state.status && this.state.status.lastQuotes) {

      let marketday = this.state.marketinfo.marketDays[0]; //First day

      let symbol1Data;
      let groups = [];
      if (this.state.chartGroup == "1h") {
        groups = this.state.marketinfo.marketDays.flatMap(a => a.groups1h);
      } else {
        groups = this.state.marketinfo.marketDays.flatMap(a => a.groups15m);
      };
      symbol1Data = groups.filter(element => element.symbol == symbol);

      if (symbol1Data) {

        period = `${this.state.marketinfo.marketDays.length} days`;
        max = Math.max(...symbol1Data.map(c => c.high));
        min = Math.min(...symbol1Data.map(c => c.low));
        last = this.state.status.lastQuotes.length > 0 ? this.state.status.lastQuotes[symbol] : 0;//Math.max(...symbol1Data.map(c => c.close));
        pp = (max + min + last) / 3;
        s1 = (pp * 2) - max;
        r1 = (pp * 2) - min;
        v = max - min;
      }

    }
    return <div className="p-4 rounded border-1 border- bg-light mb-2">
      <h4><LineIcon name="dashboard" /> Basic Statistics</h4>
      <div className="my-2 table-responsive">
        <Table borderless hover size="sm">
          <tbody>
            <tr>
              <th>Period</th>
              <td><span className="text-nowrap">{period}</span></td>
              <th>Grouped</th>
              <td>{this.state.chartGroup}</td>
              <th>Volatility</th>
              <td>${this.roundNumber(v)}</td>
            </tr>
            <tr>
              <th>High</th>
              <td>${this.roundNumber(max)}</td>
              <th>Low</th>
              <td>${this.roundNumber(min)}</td>
              <th>Last</th>
              <td>${this.roundNumber(last)}</td>
            </tr>
            <tr>
              <th>Resistance</th>
              <td>${this.roundNumber(r1)}</td>
              <th>Pivot</th>
              <td>${this.roundNumber(pp)}</td>
              <th>Support</th>
              <td>${this.roundNumber(s1)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  }

  renderTradeIdeas(symbol) {
    let rsi = "";
    let pos = "";
    let cor = "";
    let fullname = symbol;
    let description = "";

    if (this.state.marketinfo) {

      //let marketday = this.state.marketinfo.marketDays[0]; //First day
      if (this.state.marketinfo.relativeStrength && symbol in this.state.marketinfo.relativeStrength) {
        let r = this.state.marketinfo.relativeStrength[symbol];
        if (r <= 30) {
          if (r > 25) {
            rsi = <span>Weak buy signal</span>
          } else if (r > 20) {
            rsi = <span>Moderate buy signal</span>
          } else if (r > 10) {
            rsi = <span>Strong buy signal</span>
          } else {
            rsi = <span>Very strong buy signal</span>
          }
          //rsi = <span>{this.colorPercentage((r * 100) / 30)} LONG</span>
        } else if (r >= 70) {
          if (r < 75) {
            rsi = <span>Weak short signal</span>
          } else if (r < 80) {
            rsi = <span>Moderate short signal</span>
          } else if (r < 90) {
            rsi = <span>Strong short signal</span>
          } else {
            rsi = <span>Very strong short signal</span>
          }

          //rsi = <span>{this.colorPercentage(((r - 70) * 100) / 30)} SHORT</span>
        } else {
          rsi = <span>Neutral</span>
        }
      }

      //Position
      let symbol1Data;
      let groups = [];
      if (this.state.chartGroup == "1h") {
        groups = this.state.marketinfo.marketDays.flatMap(a => a.groups1h);
      } else {
        groups = this.state.marketinfo.marketDays.flatMap(a => a.groups15m);
      };
      symbol1Data = groups.filter(element => element.symbol == symbol);
      if (symbol1Data && symbol1Data.length > 0) {
        fullname = symbol1Data[0].name;
      }
      let open = symbol1Data.map(c => c.open)[0];
      let max = Math.max(...symbol1Data.map(c => c.high));
      let min = Math.min(...symbol1Data.map(c => c.low));
      let now = this.state.status && this.state.status.lastQuotes.hasOwnProperty(symbol) ? this.state.status.lastQuotes[symbol] : 0;
      let p = this.normalize(now, min, max, 0, 100);
      if (p != Infinity) {
        pos = <span>{this.colorPercentage(p)}</span>;
      } else {
        pos = <span>Unknown</span>
      }

      //Correlation
      if (this.state.marketinfo.correlation && symbol in this.state.marketinfo.correlation) {
        let c = this.state.marketinfo.correlation[symbol];

        let max = 0;
        let sym = "";
        for (const property in c) {
          if (c[property][0] != 1 && c[property][0] > max) {
            max = c[property][0];
            sym = property;
          }
        }
        if (max >= 0.8) {
          cor = <span>{sym}</span>;
        } else {
          cor = <span>None</span>;
        }
      }


      let fallenraised = (open > now) ? "fallen" : "raised";

      let pastDays = "past " + this.state.marketinfo.marketDays.length + " days";
      if (this.state.marketinfo.marketDayCount == 0)
        pastDays = "hours";
      if (this.state.marketinfo.marketDayCount == 1)
        pastDays = "past day";

      description = <p>The price of {fullname} ({symbol}) has {fallenraised} by ${this.roundNumber(Math.abs(open - now))} in the {pastDays}.
        The current price is ${this.roundNumber(now)}. {fullname} is ${this.roundNumber(Math.abs(max - now))} {(max - now > 0) ? "above " : "below "}
        the {pastDays} high of ${this.roundNumber(max)}.</p>
    }

    return (
      <div className="p-4 rounded border-1 border- bg-light mb-2">
        <h4><LineIcon name="bulb" /> Trade Ideas</h4>
        {description}
        <div className="my-2 table-responsive">
          <Table borderless hover size="sm">
            <tbody>
              <tr>
                <th>Strength</th>
                <td>{rsi}</td>
                <td><small className="text-muted">How likely the {fullname} market will turn</small></td>
              </tr>
              <tr>
                <th>Position</th>
                <td>{pos}</td>
                <td><small className="text-muted">Percentage of {fullname} market price range</small></td>
              </tr>
              <tr>
                <th>Friends</th>
                <td>{cor}</td>
                <td><small className="text-muted">How strongly {fullname} is related to another one</small></td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <small className="text-muted"><LineIcon name="bullhorn" /> Under no circumstances will CryptoDude be held responsible or liable in any way for any claims or trade losses. Trade at your own risk.</small>
        </div>
      </div>
    );
  }

  renderChart(symbol, symbol2) {
    let fullname = symbol;
    let fullname2 = symbol2;
    let chartData = [];
    let chartData2 = [];
    let chartDataArea = [];
    let chartTitle = null;
    let chartSubTitle = "";
    if (this.state.marketinfo && this.state.marketinfo.marketDays && this.state.marketinfo.marketDays.length > 0) {

      let marketday = this.state.marketinfo.marketDays[0]; //First day

      let groups = [];

      if (this.state.chartGroup == "1h") {
        groups = this.state.marketinfo.marketDays.flatMap(a => a.groups1h);
      } else {
        groups = this.state.marketinfo.marketDays.flatMap(a => a.groups15m);
      };

      let symbol1Data;
      symbol1Data = groups.filter(element => element.symbol == symbol);
      if (symbol1Data && symbol1Data.length > 0) {
        fullname = symbol1Data[0].name;
      }
      symbol1Data = symbol1Data.flatMap(a => a.ohlcData);

      let symbol2Data;
      if (symbol2) {
        symbol2Data = groups.filter(element => element.symbol == symbol2);
        if (symbol2Data && symbol2Data.length > 0) {
          fullname2 = symbol2Data[0].name;
        }
        symbol2Data = symbol2Data.flatMap(a => a.ohlcData);
      }

      if (symbol1Data) {

        chartData = symbol1Data.map(e => ({ x: new Date(e.t), y: ((e.h + e.l + e.c) / 3) }));
        chartDataArea = symbol1Data.map(e => ({ x: new Date(e.t), y: e.h, y0: e.l }));
        if (symbol2 && symbol2Data) {
          chartData2 = symbol2Data.map(e => ({ x: new Date(e.t), y: ((e.h + e.l + e.c) / 3) }));
          const maxFrom = Math.max(...chartData2.map(item => item.y));
          const maxTo = Math.max(...chartData.map(item => item.y));
          chartData2 = chartData2.map(e => ({ x: e.x, y: this.normalize(e.y, 0, maxFrom, 0, maxTo) }));
        }

        console.log(fullname + " chart loaded, " + chartData.length + " records");

        let goesDown = (chartData[0].x > chartData[chartData.length - 1].x);
        let arrow = (<LineIcon name="arrow-down" className={goesDown ? "text-red" : "text-success"} />);

        chartTitle = <span><LineIcon name={goesDown ? "stats-down" : "stats-up"} /> {fullname} <small className="text-muted">({symbol})</small> {arrow}</span>;

        //Sub title
        var parts = new Date(this.state.marketinfo.marketDays[0].date).toISOString().substring(0, 10).split("-");
        if (this.state.marketDays == 0) {
          chartSubTitle = <span>Today ({parts[2]}-{parts[1]}-{parts[0]})</span>
        } else {
          var parts2 = new Date(this.state.marketinfo.marketDays[this.state.marketinfo.marketDays.length - 1].date).toISOString().substring(0, 10).split("-");
          chartSubTitle = <span>Showing {parts2[2]}-{parts2[1]}-{parts2[0]} till Today ({parts[2]}-{parts[1]}-{parts[0]}) {(fullname2) ? <span>relative to <i className="text-warning">{fullname2} ({symbol2})</i></span> : ""}</span>;
        }
      } else {
        chartTitle = <span><LineIcon name="warning"></LineIcon> Not available <small>(yet)</small></span>;
      }

    }

    return (
      <div className="p-4 rounded rounded-3 border border-2 border-light mb-2">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="">{chartTitle}
            </h4>
            <small className="text-muted">{chartSubTitle}</small>
          </div>
          {/* <div className="d-flex justify-content-between"> */}
          {/* <Button size="sm" variant="secondary" className="d-inline btn btn-secondary btn-sm" onClick={e => this.btnChartGroupClick(this.state.chartGroup == "1h" ? "15m" : "1h")}>{this.state.chartGroup == "1h" ? "15m" : "1h"}</Button> */}
          <DropdownButton id="d-inline dropdown-item-button btn-sm" size="sm" variant="secondary" title={<span><LineIcon name="eye" /> View</span>}>
            <Dropdown.Item as="button" onClick={e => this.btnChartGroupClick("15m")} defaultChecked={true}>Group 15m</Dropdown.Item>
            <Dropdown.Item as="button" onClick={e => this.btnChartGroupClick("1h")}>Group 1h</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as="button" onClick={e => this.btnPeriodClick(0)}>View Today</Dropdown.Item>
            <Dropdown.Item as="button" onClick={e => this.btnPeriodClick(1)}>View 2 days</Dropdown.Item>
            <Dropdown.Item as="button" onClick={e => this.btnPeriodClick(2)}>View 3 days</Dropdown.Item>
            <Dropdown.Item as="button" onClick={e => this.btnPeriodClick(4)}>View 5 days</Dropdown.Item>
          </DropdownButton>
          {/* </div> */}
        </div>
        <FlexibleWidthXYPlot height={300}>
          <AreaSeries
            className="area-elevated-series-1"
            color="#dddddd"
            data={chartDataArea} />

          <HorizontalGridLines style={{ stroke: '#ededed' }} />
          <VerticalGridLines style={{ stroke: '#ededed' }} />

          <LineSeries
            className="second-series"
            data={chartData2}
            color="#ff9900"
            style={{
              strokeLinejoin: 'round',
              strokeWidth: 1
            }} />

          <LineSeries
            className="first-series"
            data={chartData}
            color="#158CBA"
            style={{
              strokeLinejoin: 'round',
              strokeWidth: 2
            }} />

          <XAxis
            xType={'time'}
            title={this.state.chartGroup}
            style={{
              text: { stroke: 'none', fill: '#6b6b76' }
            }} />

          <YAxis title="USD ($)" />
          {/* <YAxis orientation='right' tickTotal={5} tickFormat={v => handleCustomScale(v)} />  */}
        </FlexibleWidthXYPlot>
      </div>
    );
  }

  render() {

    //Status
    let status = "";
    if (this.state.status && this.state.status.lastQuoteReceivedMinutesAgo) {
      status = <p>Last broker response was <strong>{this.state.status.lastQuoteReceivedMinutesAgo}</strong> minutes ago.</p>
    } else {
      status = <p>No status information available.</p>
    }

    //Pricebar
    let pricebar = "";
    if (this.state.status && this.state.status.lastQuotes) {
      let priceCols = [];
      for (const property in this.state.status.lastQuotes) {
        let cssClass = "col text-center rounded pricebutton ";
        cssClass += (this.state.symbol == property) ? " bg-light border-top border-4 border-primary" : "";
        priceCols.push(
          <div key={property} className={cssClass} onClick={e => this.btnSymbolClick(property)}>
            <div className="p-2" >
              <strong>{property.toUpperCase()}</strong> <br />
              <small>${this.roundNumber(this.state.status.lastQuotes[property])}</small>
            </div>
          </div>
        );
      }
      pricebar = <div className="m-2">
        <div className="row justify-content-center">
          {priceCols}
        </div>

      </div>
    }

    let loader = null;
    if (this.state.isLoading) {
      loader = <div className="spinner-border text-primary spinner-border-sm mt-3" role="status">
      </div>
    }

    //https://getbootstrap.com/docs/5.1/components/button-group/#checkbox-and-radio-button-groups
    //Result
    return (
      <div className="mb-5">
        <div className="d-flex justify-content-between">
          <h1>{this.state.textWelcome}</h1>
          {loader}
        </div>
        {pricebar}
        <div>
          {this.renderChart(this.state.symbol, this.state.symbol2)}
        </div>
        {/* 
        <div className="my-2">
          <ButtonGroup size="sm">
            <ToggleButton key="chartGroup" name="radio" type="radio" value="15m" variant="secondary" checked={this.state.chartGroup == "15m"} onChange={(e) => this.btnChartGroupClick(e.currentTarget.value)}>15m</ToggleButton>
            <ToggleButton key="chartGroup" name="radio"  type="radio" value="1h" variant="secondary" checked={this.state.chartGroup == "1h"} onChange={(e) => this.btnChartGroupClick(e.currentTarget.value)}>1h</ToggleButton>
          </ButtonGroup>
        </div> */}
        <div>
          {this.renderBasicStatsTable(this.state.symbol)}
        </div>
        <div>
          {this.renderTradeIdeas(this.state.symbol)}
        </div>
        <div>
          {this.renderCorrelationTable(this.state.symbol)}
        </div>

        <div>
          <small className="text-muted">{status}</small>
        </div>
        <button className="btn btn-sm btn-secondary" onClick={e => this.btnReloadClick(this.state.chartGroup)}><LineIcon name="reload" /> Refresh</button> &nbsp;
        <button className="btn btn-sm btn-secondary" onClick={e => this.btnExportClick(this.state.chartGroup)}><LineIcon name="cloud-download" /> Export</button>
      </div>
    );
  }

}

export default Home;
