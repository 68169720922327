import React, { Component } from 'react';

import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import LineIcon from "react-lineicons";

import logo from './logo32.png'
import './NavMenu.css';

/*
export class NavMenu extends React.Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <h1>test</h1>
    );
  }
} */

class NavMenu extends React.Component {

  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  //d-sm-inline-flex flex-sm-row-reverse
  render() {
    return (

      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 border-0" light>
          <Container>
            {/* <NavbarToggler className="m-0 border-0" onClick={this.toggleNavbar} /> */}
            <NavbarBrand><img src={logo} alt="Logo" /> Crypto<strong>Dude</strong></NavbarBrand>


            <Collapse className="" isOpen={this.state.collapsed} navbar>
              {/* <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/dashboard">Dashboard</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/calculations">Calculations</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/about">About</NavLink>
                </NavItem>
              </ul> */}
            </Collapse>
          </Container>
        </Navbar>
      </header>

    );
  }
}

export default NavMenu;

// return (
//   <header>
//     <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
//       <Container>
//         <NavbarBrand tag="{Link}" to="/"><LineIcon name="calculator" /> Crypto<strong>Dude</strong></NavbarBrand>
//         <NavbarToggler className="mr-2" />
//         <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen="{true}" navbar>
//           <ul className="navbar-nav flex-grow">
//             {/*<NavItem>*/}
//             {/*  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
//             {/*</NavItem>*/}
//             {/*<NavItem>*/}
//             {/*  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
//             {/*</NavItem>*/}
//             {/*<NavItem>*/}
//             {/*  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
//             {/*</NavItem>*/}

//           </ul>
//         </Collapse>
//       </Container>
//     </Navbar>
//   </header>);
// }