import React, { Component } from 'react';
import { Outlet } from 'react-router-dom'
import { Container } from 'reactstrap';
import NavMenu from '../NavMenu/NavMenu';
import packageJson from '../../../package.json';

import './Layout.css';

export class Layout extends React.Component {

  static displayName = Layout.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="d-flex flex-column h-100">
      <NavMenu />

      <main className="flex-shrink-0">
        <Container>
          <Outlet />
        </Container>
      </main>

      <footer className="footer mt-auto py-3 bg-light">
        <div className="container">
          <span className="text-muted">CryptoDude is an experimental platform developed by DH &amp; SS &copy; Copyright 2021-2024. Version {packageJson.version.toString()} running on react {React.version} powered by Google Cloud.</span>
        </div>
      </footer>
    </div>);
  }
}
export default Layout;